import React from "react";
import Helmet from "react-helmet";
import Settings from "../settings.json";

export const HelmetMeta = () => {
    return (
        <Helmet>
            <meta name="theme-color" content={Settings.colors.primary} />
            <title>{Settings.basics.name} | {Settings.basics.description}</title>
            <meta name="author" content={Settings.basics.name} />
            <meta name="description" content={Settings.basics.description} />
            <meta name="keywords" content={Settings.basics.keywords} />
        </Helmet>
    );
};
