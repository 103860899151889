import React, { useEffect } from "react";
import { useDencrypt } from "use-dencrypt-effect";

const chars = [ "-", ".", "/", "*", "!", "?", "#", "%", "&", "@", "$", "€", "(", ")", "[", "]", "{", "}", "<", ">", "~", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z", ];

/**
 * @param {{ text: string, interval: number }} props
 */
export const TextDecrypt = (props) => {
    const { result, dencrypt } = useDencrypt({
        chars,
        interval: props.interval || 50,
    });

    useEffect(() => {
        const updateText = () => {
            dencrypt(props.text || "");
        };

        const action = setTimeout(updateText, 0);

        return () => clearTimeout(action);
    }, [dencrypt, props.text]);

    return (
        <p>
            {result}
            {" "}
        </p>
    );
};
