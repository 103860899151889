import React from "react";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { HelmetMeta } from "./HelmetMeta";
import { ThemeProvider } from "../components/theme/ThemeProvider";
import { CssBaseline } from "@material-ui/core";
import { logCredits } from "../utils/logCredits";
import DisplacementSphere from '../components/background/DisplacementSphere';
import { LogoLink } from '../components/logo/LogoLink';
import { Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeToggle } from '../components/theme/ThemeToggle';
import { FooterText } from '../components/footer/FooterText';
import { SocialIcons } from '../components/content/SocialIcons';
import { SpeedDials } from '../components/speedDial/SpeedDial';
import { Home, Kusama } from '../components/content/Content';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
}));


export const App = () => {
    const classes = useStyles();
    logCredits();

    return (
        <ThemeProvider>
            <CssBaseline />
            <Router>
                <HelmetMeta />
                <div className={classes.root}>
                    <DisplacementSphere />
                    <LogoLink />
                    <Switch>
                        <Route path="/kusama" component={Kusama} />
                        <Route path="*" exact component={Home} />
                    </Switch>
                    <ThemeToggle />
                    <Hidden smDown>
                    <SocialIcons />
                    </Hidden>
                    <Hidden mdUp>
                    <SpeedDials />
                    </Hidden>
                    <FooterText />
                </div>
            </Router>
        </ThemeProvider>
    );
};
