import React from "react";
import { Typography, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TextDecrypt } from "./TextDecrypt";
import { Button, Link } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    main: {
        marginTop: "auto",
        marginBottom: "auto",
        "@media (max-width: 768px)": {
            marginLeft: theme.spacing(4),
        },
    },
    alignCenter: {
        textAlign: 'center',
    },
    address: {
        fontSize: '1.125rem',
        wordWrap: 'break-word',
        marginBottom: theme.spacing(3),
    },
}));

export const Home = () => {
    const classes = useStyles();

    return (
        <Container component="main" className={`${classes.main}`} maxWidth="sm">
            <Typography variant="h2" component="h1" gutterBottom>
                <TextDecrypt text="Hey, we're" />
                <TextDecrypt text="DigitalGhost" />
            </Typography>
            <Typography variant="h5" component="h2" gutterBottom>
                <TextDecrypt text="We keep Polkadot/Kusama secure." interval={30} />
            </Typography>
        </Container>
    );
};

export const Kusama = () => {
    const classes = useStyles();
    return (
        <Container component="main" className={`${classes.main} ${classes.alignCenter}`} maxWidth="sm">
            <p className={`${classes.address}`}>FkqSqeRVr4VceTBKFXzwdSAjmB1QuhhVTdN1CXhRFvtPRZa</p>
            <Link color="inherit" target="_blank" href="https://polkadot.js.org/apps/?rpc=wss%3A%2F%2Fkusama-rpc.polkadot.io#/staking/targets">
                <Button className={`MuiIconButton-root`}>
                    Stake with us
                </Button>
            </Link>
        </Container>
    );
};
